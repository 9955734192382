.showroom {
    padding: 20px 0;
    max-width: 1400px;
    margin: 0 auto;
    background-color: #f9f9f9;
}

.section-title {
    font-size: 28px;
    font-weight: 800;
    margin: 30px 0 25px 0;
    text-align: center;
    position: relative;
    color: #8B0000;
    padding-bottom: 15px;
}

.section-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 80px;
    height: 4px;
    background: linear-gradient(90deg, #8B0000, rgba(139, 0, 0, 0.5));
    transform: translateX(-50%);
    border-radius: 2px;
}

.section-title::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 30px;
    height: 4px;
    background: rgba(139, 0, 0, 0.8);
    transform: translateX(-20px);
    border-radius: 2px;
    animation: slideLine 2s ease-in-out infinite;
}

@keyframes slideLine {
    0%, 100% {
        transform: translateX(-40px);
    }
    50% {
        transform: translateX(10px);
    }
}

.showroom-ads-container { /* Benzersiz sınıf adı */
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 20px;
}

.item-card {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative; /* Premium badge için gerekli */
}

.item-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.image-container {
    width: 100%;
    height: 120px;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 10px;
}

.item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item-info {
    text-align: center;
    padding: 10px 0;
}

.item-info h3 {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.price {
    font-size: 16px;
    color: #e74c3c;
    font-weight: bold;
    margin-bottom: 5px;
}

.location {
    font-size: 14px;
    color: #666;
}

/* Premium badge */
.premium-badges {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;
}

.premium-badge {
    padding: 5px 10px;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
}

.premium-badge.featured {
    background-color: #8B0000; /* Koyu kırmızı */
}

.premium-badge.slider {
    background-color: #FF4500; /* Turuncu kırmızı */
}

.premium-badge.showroom {
    background-color: #FFD700; /* Altın rengi */
}

/* Skeleton Loader */
.skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    animation: pulse 1.5s infinite ease-in-out;
}

.skeleton-image {
    height: 120px;
    width: 100%;
}

.skeleton-text {
    height: 20px;
    width: 80%;
    margin: 10px 0;
}

.skeleton-price {
    height: 20px;
    width: 40%;
}

@keyframes pulse {
    0% {
        background-color: #f0f0f0;
    }
    50% {
        background-color: #e0e0e0;
    }
    100% {
        background-color: #f0f0f0;
    }
}

/* Responsive Stiller */
@media (max-width: 1024px) {
    .showroom-ads-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .showroom-ads-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .section-title {
        font-size: 24px;
        margin: 25px 0 20px 0;
    }

    .item-card {
        padding: 8px;
    }

    .image-container {
        height: 100px;
    }

    .item-info h3 {
        font-size: 12px;
    }

    .price {
        font-size: 14px;
    }

    .location {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .showroom-ads-container {
        grid-template-columns: 1fr;
    }

    .section-title {
        font-size: 22px;
        margin: 20px 0 15px 0;
    }

    .image-container {
        height: 80px;
    }

    .item-info h3 {
        font-size: 10px;
    }

    .price {
        font-size: 12px;
    }

    .location {
        font-size: 10px;
    }
}
