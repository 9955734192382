/* Varsayılan Masaüstü Stilleri */
.banner {
    position: relative;
    background: linear-gradient(135deg, rgba(139, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 100%), 
                url('https://images.unsplash.com/photo-1592396355679-1e2a094e8bf1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80') no-repeat center center;
    background-size: cover;
    height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    overflow: hidden;
    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.5);
    border-radius: 0 0 20px 20px;
    padding: 0 20px;
}

.banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 70% 30%, rgba(255, 153, 51, 0.1) 0%, rgba(0, 0, 0, 0.3) 100%);
    z-index: 1;
}

/* Dekoratif hareketli şekiller */
.shape {
    position: absolute;
    border-radius: 50%;
    z-index: 1;
    opacity: 0.2;
}

.shape1 {
    width: 380px;
    height: 380px;
    background: linear-gradient(135deg, #8B0000, #ff9933);
    bottom: -180px;
    left: -100px;
    animation: float 15s ease-in-out infinite;
}

.shape2 {
    width: 250px;
    height: 250px;
    background: linear-gradient(135deg, #ff9933, #8B0000);
    top: -80px;
    right: 10%;
    animation: float 18s ease-in-out infinite reverse;
}

.shape3 {
    width: 180px;
    height: 180px;
    background: linear-gradient(135deg, #fff, #ff9933);
    bottom: 20%;
    right: -50px;
    animation: float 12s ease-in-out infinite;
    opacity: 0.15;
}

.shape4 {
    width: 150px;
    height: 150px;
    background: linear-gradient(135deg, #8B0000, #000);
    top: 15%;
    left: 10%;
    animation: float 10s ease-in-out infinite 2s;
}

/* Banner İçerik Konteyner */
.banner .banner-content {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Metin içeriği */
.text-content {
    text-align: center;
    margin-bottom: 30px;
    width: 100%;
}

.title-container {
    margin-bottom: 30px;
}

.banner .banner-content h1 {
    font-size: 54px;
    font-weight: 900;
    margin-bottom: 15px;
    letter-spacing: 0.5px;
    line-height: 1.2;
    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.6);
    animation: fadeDown 1s ease-out;
}

.banner .banner-content h1 .highlight {
    color: #ff9933;
    position: relative;
    display: inline-block;
}

.banner .banner-content h1 .highlight:after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: #ff9933;
    border-radius: 4px;
    opacity: 0.3;
    z-index: -1;
}

.subtitle-container {
    animation: fadeUp 1.2s ease-out;
}

.banner .banner-content p {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 20px;
    font-weight: 400;
    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
}

.stats {
    font-weight: 700;
    color: #ff9933;
}

/* Arama kutusu */
.search-container {
    width: 100%;
    max-width: 700px;
    margin: 0 auto 40px;
    animation: fadeUp 1.4s ease-out;
}

.search-box {
    display: flex;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 50px;
    padding: 5px 5px 5px 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.search-box input {
    flex: 1;
    border: none;
    padding: 15px 10px;
    font-size: 16px;
    background: transparent;
    color: #333;
}

.search-box input:focus {
    outline: none;
}

.search-button {
    background: linear-gradient(135deg, #8B0000 0%, #a70000 100%);
    color: white;
    border: none;
    border-radius: 50px;
    padding: 15px 25px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.search-button:hover {
    background: linear-gradient(135deg, #a70000 0%, #c00000 100%);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(139, 0, 0, 0.3);
}

/* Kategori kutuları */
.category-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;
    animation: fadeUp 1.6s ease-out;
}

.category-box {
    background: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(8px);
    border-radius: 16px;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.15);
}

.category-box:hover {
    background: rgba(255, 255, 255, 0.25);
    transform: translateY(-8px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
}

.category-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 50%;
    margin-bottom: 8px;
    transition: all 0.3s ease;
}

.category-box:hover .category-icon-container {
    background: rgba(255, 255, 255, 0.3);
    transform: scale(1.1);
}

.category-icon {
    font-size: 24px;
    color: #fff;
    transition: all 0.3s ease;
}

.category-box:hover .category-icon {
    color: #ff9933;
    transform: scale(1.1);
}

.category-box span {
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease;
}

.category-box:hover span {
    color: #ff9933;
}

/* İlan ver butonu */
.post-ad-button-container {
    position: relative;
    margin-top: 40px;
    text-align: center;
    z-index: 10;
    animation: fadeUp 1.8s ease-out;
}

.post-ad-button {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    background: linear-gradient(135deg, #ff9933 0%, #ff7700 100%);
    color: white;
    text-decoration: none;
    padding: 18px 40px;
    border-radius: 50px;
    font-weight: 700;
    font-size: 18px;
    box-shadow: 0 15px 35px rgba(255, 153, 51, 0.5);
    transition: all 0.3s ease;
    border: 2px solid rgba(255, 255, 255, 0.3);
}

.post-ad-button:hover {
    background: linear-gradient(135deg, #c00000 0%, #ff3333 100%);
    transform: translateY(-3px);
}

.button-icon {
    margin-right: 10px;
}

/* Mobil Uyumluluk */
@media (max-width: 1024px) {
    .banner {
        height: 580px;
        padding: 0 15px;
    }
    
    .post-ad-button-container {
        margin-top: 35px;
    }
}

@media (max-width: 768px) {
    .banner .banner-content h1 {
        font-size: 36px;
    }
    
    .search-box {
        padding: 3px 3px 3px 15px;
    }
    
    .category-boxes {
        gap: 12px;
    }
    
    .category-box {
        width: 85px;
        height: 85px;
    }
    
    .post-ad-button {
        padding: 14px 25px;
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .banner .banner-content h1 {
        font-size: 28px;
    }
    
    .banner .banner-content p {
        font-size: 16px;
    }
    
    .category-boxes {
        gap: 10px;
    }
    
    .category-box {
        width: 70px;
        height: 70px;
    }
    
    .category-icon-container {
        width: 40px;
        height: 40px;
    }
    
    .category-icon {
        font-size: 18px;
    }
    
    .category-box span {
        font-size: 12px;
    }
    
    .post-ad-button {
        padding: 12px 20px;
        font-size: 14px;
    }
    
    .button-icon {
        font-size: 16px;
    }
}

/* Animasyonlar */
@keyframes float {
    0%, 100% {
        transform: translateY(0) rotate(0deg);
    }
    25% {
        transform: translateY(-20px) rotate(5deg);
    }
    50% {
        transform: translateY(0) rotate(0deg);
    }
    75% {
        transform: translateY(20px) rotate(-5deg);
    }
}

@keyframes fadeDown {
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate(-50%, 30px);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 153, 51, 0.4);
    }
    70% {
        transform: scale(1.05);
        box-shadow: 0 0 0 15px rgba(255, 153, 51, 0);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 153, 51, 0);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}