/* Boş ilan şablonu için stilller */
.template-card {
    opacity: 0.9; /* Öncekinden daha belirgin olsun */
    transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
    border: 1px solid rgba(139, 0, 0, 0.1);
    position: relative;
}

.template-card:hover {
    opacity: 1;
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    border-color: rgba(139, 0, 0, 0.3);
}

.template-card .item-image {
    filter: grayscale(20%); /* Az grayscale efekti verelim */
    transition: all 0.5s ease;
}

.template-card:hover .item-image {
    filter: grayscale(0%); /* Hover'da grayscale'i kaldıralım */
    transform: scale(1.05);
}

.template-card .category-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(139, 0, 0, 0.9);
    color: white;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    z-index: 2;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    letter-spacing: 0.5px;
}

.template-card .save-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    color: #ddd;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    z-index: 2;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.template-card .location-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-bottom: 5px;
}

.template-card .location-icon {
    color: #8B0000;
    font-size: 12px;
}

.template-card .listing-date {
    font-size: 11px;
    color: #888;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.template-card .time-icon {
    color: #8B0000;
    font-size: 11px;
}

/* Mobil için uyarlama */
@media (max-width: 768px) {
    .template-card .category-tag {
        font-size: 9px;
        padding: 2px 6px;
    }
    
    .template-card .save-icon {
        width: 22px;
        height: 22px;
        font-size: 11px;
    }
    
    .template-card .listing-date {
        font-size: 10px;
    }
}