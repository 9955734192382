    /* src/components/FeaturedAds.css */

    .featured-ads {
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* 4 ilan yan yana olacak */
        gap: 30px;
        margin-bottom: 50px;
    }

    .featured-item-link {
        text-decoration: none;
        color: inherit;
    }

    .featured-item {
        position: relative;
        background-color: white;
        border-radius: 16px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        min-height: 380px;
        max-height: 420px;
        transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.08);
    }

    .featured-item:hover {
        transform: translateY(-15px);
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.3);
        border-color: rgba(139, 0, 0, 0.2);
    }

    /* VIP rozeti */
    .vip-badge {
        position: absolute;
        top: 16px;
        right: 16px;
        background: linear-gradient(45deg, #8B0000, #cc0000);
        color: white;
        padding: 5px 12px;
        border-radius: 20px;
        font-size: 12px;
        font-weight: bold;
        z-index: 10;
        display: flex;
        align-items: center;
        gap: 6px;
        box-shadow: 0 5px 15px rgba(139, 0, 0, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.2);
    }

    .vip-icon {
        font-size: 14px;
        color: #FFD700;
        animation: shineIcon 2s infinite alternate;
    }

    /* Görsel kapsayıcısı */
    .featured-image-container {
        width: 100%;
        height: 220px;
        overflow: hidden;
        position: relative;
    }

    .featured-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s ease;
    }

    .featured-item:hover .featured-image {
        transform: scale(1.08);
    }

    /* Kategori etiketi */
    .featured-category {
        position: absolute;
        top: 16px;
        left: 16px;
        background-color: rgba(139, 0, 0, 0.85);
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: bold;
        z-index: 3;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    }

    /* İlan bilgileri */
    .featured-info {
        padding: 20px;
        flex-grow: 1;
    }

    .featured-info h3 {
        font-size: 17px;
        color: #333;
        margin: 0 0 10px 0;
        font-weight: bold;
        height: 42px;
        line-height: 1.3;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .featured-info .price {
        font-size: 20px;
        color: #8B0000;
        font-weight: bold;
        margin: 0 0 8px 0;
    }

    .featured-info .location {
        font-size: 14px;
        color: #666;
        margin: 0 0 15px 0;
        display: flex;
        align-items: center;
        gap: 6px;
    }

    .location-icon {
        color: #8B0000;
        font-size: 14px;
    }

    /* Gösterim istatistikleri ve favorilere ekle düğmesi */
    .featured-actions {
        position: absolute;
        bottom: 15px;
        right: 15px;
        display: flex;
        gap: 10px;
        z-index: 3;
    }

    .action-button {
        width: 36px;
        height: 36px;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ccc;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    }

    .save-button:hover {
        background-color: #8B0000;
        color: white;
        transform: scale(1.1);
    }

    .stats-indicator {
        background-color: rgba(0, 0, 0, 0.6);
        color: white;
        padding: 5px 10px;
        border-radius: 20px;
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    /* Tarih etiketi */
    .featured-date {
        position: absolute;
        bottom: 15px;
        left: 15px;
        background-color: rgba(0, 0, 0, 0.6);
        color: white;
        padding: 5px 10px;
        border-radius: 20px;
        font-size: 12px;
        z-index: 3;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    /* Özellikler listesi */
    .features-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 5px;
    }

    .feature {
        background-color: #f5f5f5;
        color: #333;
        padding: 5px 10px;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 4px;
        transition: all 0.3s ease;
    }

    .feature:hover {
        background-color: #e0e0e0;
        transform: translateY(-3px);
    }

    .feature-icon {
        color: #8B0000;
        font-size: 10px;
    }

    /* İlanı görüntüle butonu */
    .view-details-button {
        text-align: center;
        background: linear-gradient(135deg, #8B0000, #cc0000);
        color: white;
        padding: 12px 0;
        font-weight: bold;
        text-decoration: none;
        transition: all 0.3s ease;
        width: 100%;
        display: block;
    }

    .view-details-button:hover {
        background: linear-gradient(135deg, #cc0000, #ff0000);
    }

    /* Skeleton loader stili */
    .skeleton-featured {
        min-height: 380px;
        background-color: #f8f8f8;
        position: relative;
        overflow: hidden;
    }

    .skeleton-featured::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0,
            rgba(255, 255, 255, 0.2) 20%,
            rgba(255, 255, 255, 0.5) 60%,
            rgba(255, 255, 255, 0)
        );
        animation: shimmer 2s infinite;
    }

    .skeleton-image {
        width: 100%;
        height: 220px;
        background-color: #e0e0e0;
    }

    .skeleton-content {
        padding: 20px;
    }

    .skeleton-title {
        height: 42px;
        background-color: #e0e0e0;
        margin-bottom: 10px;
        border-radius: 4px;
    }

    .skeleton-price {
        height: 22px;
        width: 40%;
        background-color: #e0e0e0;
        margin-bottom: 8px;
        border-radius: 4px;
    }

    .skeleton-location {
        height: 16px;
        width: 70%;
        background-color: #e0e0e0;
        margin-bottom: 15px;
        border-radius: 4px;
    }

    .skeleton-features {
        display: flex;
        gap: 10px;
    }

    .skeleton-features::before,
    .skeleton-features::after {
        content: '';
        height: 24px;
        border-radius: 20px;
        background-color: #e0e0e0;
    }

    .skeleton-features::before {
        width: 30%;
    }

    .skeleton-features::after {
        width: 40%;
    }

    /* Animasyonlar */
    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }

    @keyframes shineIcon {
        0% {
            text-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
        }
        100% {
            text-shadow: 0 0 15px rgba(255, 215, 0, 1), 0 0 20px rgba(255, 215, 0, 0.7);
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(30px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    /* Responsive tasarım */
    @media (max-width: 1024px) {
        .featured-ads {
            grid-template-columns: repeat(2, 1fr); /* 2 sütun olacak şekilde düzenlendi */
            gap: 25px;
        }
    }

    @media (max-width: 600px) {
        .featured-ads {
            grid-template-columns: 1fr; /* Tek sütun olacak şekilde düzenlendi */
            gap: 20px;
        }
        
        .featured-item {
            min-height: 350px;
        }
        
        .featured-image-container {
            height: 200px;
        }
        
        .vip-badge {
            padding: 4px 10px;
            font-size: 11px;
        }
        
        .featured-category {
            font-size: 11px;
            padding: 4px 8px;
        }
        
        .featured-info h3 {
            font-size: 16px;
        }
    }
