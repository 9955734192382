/* Ana Bölüm */
.promo-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 18px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    margin: 50px auto;
    max-width: 1200px;
    position: relative;
    overflow: hidden;
}

.promo-section::before {
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    background: linear-gradient(to right, rgba(139, 0, 0, 0.03), rgba(255, 150, 79, 0.03));
    border-radius: 50%;
    top: -150px;
    left: -150px;
    z-index: 0;
}

.promo-section::after {
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    background: linear-gradient(to left, rgba(139, 0, 0, 0.03), rgba(255, 150, 79, 0.03));
    border-radius: 50%;
    bottom: -150px;
    right: -150px;
    z-index: 0;
}

/* Ortak Uygulama Stili */
.promo-app {
    padding: 40px 30px;
    border-radius: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 400px;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.promo-app:hover {
    transform: translateY(-8px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

/* İlanPorts App Stili */
.ilanports-app {
    background: linear-gradient(150deg, #8B0000, #B30000);
    color: white;
    box-shadow: 0 10px 25px rgba(139, 0, 0, 0.3);
}

.ilanports-app::before {
    content: '';
    position: absolute;
    top: -80px;
    left: -80px;
    width: 200px;
    height: 200px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    z-index: 0;
}

.ilanports-app::after {
    content: '';
    position: absolute;
    bottom: -60px;
    right: -60px;
    width: 150px;
    height: 150px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    z-index: 0;
}

/* TencereApp Stili */
.tencereapp {
    background: linear-gradient(150deg, #FF964F, #FF7417);
    color: white;
    box-shadow: 0 10px 25px rgba(255, 150, 79, 0.3);
}

.tencereapp::before {
    content: '';
    position: absolute;
    top: -60px;
    right: -60px;
    width: 150px;
    height: 150px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    z-index: 0;
}

.tencereapp::after {
    content: '';
    position: absolute;
    bottom: -80px;
    left: -80px;
    width: 200px;
    height: 200px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    z-index: 0;
}

/* Uygulama İkonu */
.app-icon {
    margin: 0 auto 30px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25), 0 0 0 8px rgba(255, 255, 255, 0.1);
    position: relative;
    z-index: 2;
    transition: all 0.3s ease;
    border: 2px solid rgba(255, 255, 255, 0.2);
}

.promo-app:hover .app-icon {
    transform: scale(1.05);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3), 0 0 0 12px rgba(255, 255, 255, 0.15);
}

.app-icon i {
    font-size: 50px;
    color: white;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Başlık ve Açıklama */
.promo-app h2 {
    font-size: 32px;
    margin-bottom: 15px;
    font-weight: 700;
    position: relative;
    z-index: 1;
    letter-spacing: 0.5px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.promo-app p {
    font-size: 17px;
    margin-bottom: 30px;
    opacity: 0.95;
    line-height: 1.6;
    position: relative;
    z-index: 1;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

/* Özellikler Konteyneri */
.features-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-bottom: 35px;
    position: relative;
    z-index: 1;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 15px;
    text-align: left;
    transition: transform 0.3s ease;
    padding: 10px 15px;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.1);
}

.feature-item:hover {
    transform: translateX(5px);
    background-color: rgba(255, 255, 255, 0.15);
}

.feature-item i {
    font-size: 20px;
    color: white;
    min-width: 24px;
    text-align: center;
}

.feature-item span {
    font-size: 16px;
    font-weight: 500;
}

/* İndirme Butonları */
.download-buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: auto;
    position: relative;
    z-index: 1;
    width: 100%;
}

.download-button {
    background-color: rgba(255, 255, 255, 0.15);
    color: white;
    padding: 12px 25px;
    border-radius: 50px;
    font-size: 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    font-weight: 500;
    flex: 1;
    max-width: 160px;
}

.download-button i {
    font-size: 22px;
}

.download-button:hover {
    background-color: rgba(255, 255, 255, 0.25);
    transform: translateY(-5px);
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
}

/* Mobil Cihazlar için Uyarlama */
@media (max-width: 768px) {
    .promo-section {
        grid-template-columns: 1fr;
        padding: 25px 20px;
    }

    .promo-app {
        min-height: 380px;
        margin-bottom: 25px;
        padding: 30px 20px;
    }
    
    .app-icon {
        width: 90px;
        height: 90px;
        margin-bottom: 25px;
    }
    
    .app-icon i {
        font-size: 40px;
    }
    
    .promo-app h2 {
        font-size: 28px;
        margin-bottom: 12px;
    }
    
    .promo-app p {
        font-size: 16px;
        margin-bottom: 25px;
    }
    
    .feature-item {
        padding: 8px 12px;
    }
    
    .feature-item i {
        font-size: 18px;
    }
    
    .feature-item span {
        font-size: 15px;
    }
}

@media (max-width: 480px) {
    .download-buttons {
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }
    
    .download-button {
        width: 100%;
        max-width: 200px;
    }
}