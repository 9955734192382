/* frontend/src/components/Slider.css */

.slider {
    overflow: hidden;
    position: relative;
    height: 260px; /* Yüksekliği artırdım */
    margin-bottom: 40px;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    padding: 20px 0;
    background: linear-gradient(to right, rgba(255,255,255,0.5), rgba(255,255,255,0.2));
}

.slider-track {
    display: flex;
    gap: 20px; /* Elemanlar arasında daha fazla boşluk */
    animation: slide 30s linear infinite;
    padding: 0 20px;
    width: max-content; /* İçerik genişliğine göre ayarlanacak */
}

.slider-item,
.slider-ad-item {
    min-width: 230px; /* İlan kutularının genişliği artırıldı */
    height: 230px; /* Yükseklik artırıldı */
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.slider-item:hover,
.slider-ad-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    border-color: rgba(139, 0, 0, 0.1);
}

/* Link için stil */
.slider-item-link {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.slider-image-container {
    height: 130px; /* Görselin alanı */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Görselin tam olarak görünmesi için */
    transition: transform 0.5s ease;
}

.slider-item:hover .slider-image {
    transform: scale(1.1);
}

/* Kategori etiketi */
.slider-category {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(139, 0, 0, 0.9); /* Daha az transparan */
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    z-index: 3; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Gölge artırıldı */
    letter-spacing: 0.5px; /* Harf aralığı eklendi */
}

/* Favorilere ekle butonu */
.slider-save {
    position: absolute;
    top: 45px; /* 10px'ten 45px'e değiştirildi */
    right: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    color: #ccc;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 4; /* 2'den 4'e çıkarıldı */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.slider-save:hover {
    background-color: #8B0000;
    color: white;
    transform: scale(1.1);
}

.slider-info {
    padding: 12px;
    text-align: left;
    flex-grow: 1;
}

.slider-info h3 {
    font-size: 15px;
    font-weight: bold;
    color: #333;
    margin: 0 0 5px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.slider-info .price {
    font-size: 16px;
    color: #8B0000;
    font-weight: bold;
    margin: 0 0 5px 0;
}

.slider-info .location {
    font-size: 12px;
    color: #666;
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 0;
}

.location-icon {
    font-size: 11px;
    color: #8B0000;
}

/* ACİL etiketi */
.urgent-badge {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(90deg, #8B0000, #b33939);
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.fire-icon {
    font-size: 12px;
    animation: fireGlow 1.5s ease-in-out infinite alternate;
}

/* Premium badge */
.ribbon {
    position: absolute;
    top: 10px; /* 15px'ten 10px'e değiştirildi */
    right: -30px; /* -35px'ten -30px'e değiştirildi */
    width: 120px; /* 130px'ten 120px'e değiştirildi */
    height: 22px; /* 25px'ten 22px'e değiştirildi */
    background: linear-gradient(45deg, #8B0000, #cc0000);
    color: white;
    line-height: 22px; /* 25px'ten 22px'e değiştirildi */
    font-weight: bold;
    font-size: 11px; /* 12px'ten 11px'e değiştirildi */
    transform: rotate(45deg);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    z-index: 2;
    display: flex;
    justify-content: center;
    border: 1px dashed rgba(255, 255, 255, 0.5);
}

/* Placeholder için stil */
.placeholder-item {
    opacity: 0.9;
}

/* Skeleton loader stil */
.skeleton-item {
    min-width: 230px;
    height: 230px;
    background-color: #f8f8f8;
    border-radius: 12px;
    padding: 0;
    position: relative;
    overflow: hidden;
}

.skeleton-item::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
}

.skeleton-image {
    width: 100%;
    height: 130px;
    background-color: #e0e0e0;
}

.skeleton-text {
    height: 16px;
    margin: 12px 10px 5px 10px;
    background-color: #e0e0e0;
    border-radius: 4px;
}

.skeleton-price {
    height: 14px;
    width: 60%;
    margin: 8px 10px 5px 10px;
    background-color: #e0e0e0;
    border-radius: 4px;
}

.skeleton-location {
    height: 10px;
    width: 80%;
    margin: 8px 10px;
    background-color: #e0e0e0;
    border-radius: 4px;
}

/* Sonsuz kaydırma animasyonu - kesintisiz döngü için yeniden düzenlendi */
@keyframes slide {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-50%)); /* Slider içeriğinin yarısı kadar kaydırma */
    }
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

@keyframes fireGlow {
    0% {
        text-shadow: 0 0 5px rgba(255, 165, 0, 0.5);
        transform: scale(1);
    }
    100% {
        text-shadow: 0 0 10px rgba(255, 165, 0, 0.8), 0 0 15px rgba(255, 69, 0, 0.6);
        transform: scale(1.2);
    }
}

@media (max-width: 768px) {
    .slider {
        height: 240px;
    }
    
    .slider-item,
    .slider-ad-item {
        min-width: 190px;
        height: 210px;
    }
    
    .slider-info h3 {
        font-size: 14px;
    }
    
    .slider-info .price {
        font-size: 15px;
    }
    
    .ribbon {
        width: 100px;
        height: 20px;
        font-size: 10px;
        line-height: 20px;
        right: -25px;
    }
    
    .slider-category {
        font-size: 10px;
        padding: 3px 6px;
    }
    
    .slider-save {
        top: 40px;
        width: 24px;
        height: 24px;
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .slider {
        height: 220px;
    }
    
    .slider-item,
    .slider-ad-item {
        min-width: 160px;
        height: 190px;
    }
    
    .slider-image-container {
        height: 100px;
    }
    
    .slider-info h3 {
        font-size: 13px;
    }
    
    .slider-info .price {
        font-size: 14px;
    }
    
    .ribbon {
        width: 90px;
        height: 18px;
        font-size: 9px;
        line-height: 18px;
        right: -22px;
        top: 8px;
    }
    
    .slider-category {
        font-size: 9px;
        padding: 2px 5px;
    }
    
    .slider-save {
        top: 35px;
        width: 22px;
        height: 22px;
        font-size: 11px;
    }
    
    .urgent-badge {
        font-size: 10px;
    }
}
